
   import Camera from "simple-vue-camera";
   import {computed, ref} from "vue";
   import axios from "axios";
   import { useStore } from 'vuex'
   import { useRouter } from 'vue-router'
   import {useToast} from "primevue/usetoast";
   import {Ds_Cls} from "@/models/ds_Cls";
   import zaloRepository from "@/services/ZaloRepository";
   import moment from "moment";
   import {ThongTinBN} from "@/models/thongTinBN";

   export default {
  setup(){
    const camera = ref<InstanceType<typeof Camera>>();
    const lat = ref(0);
    const long = ref(0);
    const toast = useToast();
    const checkin = ref(false);
    const loadingBar = ref(false);
    const cameraPermission = ref(true);
    const store = useStore();
    const url = ref();

    const listBlob = ref([] as string[]);
    const listUrl = ref([] as string[]);
    const maYTe = ref("");
    const thongTinBN = ref({} as ThongTinBN);
    const ngaySinh = ref("");

    const loadListCls = () => {
      zaloRepository.getThongTinBN(maYTe.value)
          .then((response) => {
            thongTinBN.value = response.data;
            if(thongTinBN.value != null)
            {
              ngaySinh.value = formatDate(thongTinBN.value.ngaysinh);
            }
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tìm thông tin bệnh nhân',
              life: 2000
            });
          });
    }

    const formatDateTime = (date) => {
      console.log("@@@@@@@@@################ formatDateTime date: " + date.value);
      if((date == null)||(date == ''))
      {
        return "chưa có thông tin"
      }
      else
        return moment(String(date)).format('HH:mm:ss DD/MM/YYYY');
    };

    const formatDate = (date) => {
      console.log("@@@@@@@@@################ formatDateTime date: " + date.value);
      if((date == null)||(date == ''))
      {
        return "chưa có thông tin"
      }
      else
        return moment(String(date)).format('DD/MM/YYYY');
    };

    const snapshot = async () => {
      const blob = ref();
      if(camera.value != null)
      {
        if (camera.value?.resolution.width < camera.value?.resolution.height) {
          blob.value = await camera.value?.snapshot(
              {width: 540, height: 960},
              "image/jpeg",
              0.5
          );
        } else {
          blob.value = await camera.value?.snapshot(
              {width: 960, height: 540},
              "image/jpeg",
              0.5
          );
        }
      }

      console.log("######## height: " + JSON.stringify(camera.value?.resolution));

      // To show the screenshot with an image tag, create a url
       url.value = URL.createObjectURL(blob.value);
      if(url.value != null) {
        console.log("######################## url.value: " + url.value);
        listUrl.value.push(url.value);
        listBlob.value.push(blob.value);
        console.log("######################## listUrl.value: " + JSON.stringify(listUrl.value));
      }
    }

    const uploadImage = () => {

      // open(url);
      var files = new FormData();
      if(listBlob?.value.length > 0) {
        listBlob.value.forEach(x => {
          const urlTemp = URL.createObjectURL(x);
          const str = urlTemp.split('/');
          const blobFile = new File([x], str[str.length - 1] + '.jpeg');
          files.append("formFiles", blobFile);
        })
        axios.defaults.headers.common = {'Authorization': `Bearer ${store.state.token}`}
        {
          if(!cameraPermission.value){
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:"Vui lòng bật quyền truy cập máy ảnh(camera) và thực hiện lại",
              life: 4000
            });
          }
          else {
            loadingBar.value = true;
            axios.post(`/api/file/UploadFacePatient/${maYTe.value}`, files,
                {
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                  }
                }).then(response => {
              loadingBar.value = false;
              toast.add({
                severity: 'success',
                summary: 'Thành công',
                detail:'Tải ảnh lên thành công',
                life: 2000
              });
              listBlob.value = [];
              listUrl.value = [];
              const temp = ref({} as ThongTinBN);
              thongTinBN.value = temp.value;
              maYTe.value = "";
            })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2500
                  })});
          }
        }
      }
    }

    const error = (error: Error) => {
      cameraPermission.value = false;
    };

    const deleteImage = (id) => {
      const index = ref(0);
      listUrl.value.forEach( x => {
        if(x == id) {
          listUrl.value.splice(index.value, 1);
          listBlob.value.splice(index.value, 1);
        }
        else
        {
          index.value++;
        }
      })
    }

    return {
      camera,
      snapshot,
      lat,
      long,
      checkin,
      loadingBar,
      error,
      url,
      listUrl,
      deleteImage,
      uploadImage,
      loadListCls,
      thongTinBN,
      ngaySinh,
      maYTe,
      listBlob,
      // blobPhoto
    };

  }
}
