

import { computed, ref } from 'vue'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import {ListCustomer} from "@/models/listCustomer";
import zaloRepository from "@/services/ZaloRepository";
import {FilterMatchMode} from "primevue/api";
import {ManagerCheckinCreate} from "@/models/managerCheckinCreate";
import {ManagerCheckin} from "@/models/managerCheckin";
import {ManagerBookingCreate} from "@/models/managerBookingCreate";
import {ManagerBooking} from "@/models/managerBooking";
import {DoctorExamineCreate} from "@/models/doctorExamineCreate";
import {EmployeeInHospital} from "@/models/employeeInHospital";
import {ReceiverStatistical} from "@/models/receiverStatistical";
import {ReceiverStatisticalCreate} from "@/models/receiverStatisticalCreate";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listCustomer = ref([] as ListCustomer[]);
    const receiverStatistical = ref({} as ReceiverStatisticalCreate);
    const listReceiverStatistical = ref([] as ReceiverStatistical[]);

    const loadData = () => {
      zaloRepository.getListCustomer()
          .then((response) => {
            listCustomer.value = response.data;
            loadListStatistical();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:'Lỗi khi tải danh sách khách hàng',
              life: 2000
            });
          });
    }

    loadData();

    const loadListStatistical = () => {
      zaloRepository.getListReceiverStatistical()
          .then((response) => {
            listReceiverStatistical.value = response.data;
            listReceiverStatistical.value.forEach(x => {
              listCustomer.value.filter(y => {
                if(y.id == x.idZalo) x.imageLink = y.imageUrl;
              })
            });
          })
          .catch(err => {
            // toast.add({
            //   severity: 'error',
            //   summary: 'Lỗi',
            //   detail:'Lỗi khi tải danh sách nhận góp ý',
            //   life: 2000
            // });
          });
    }



    const addMember = () => {
      console.log("############# add member receiverStatistical: " + JSON.stringify(receiverStatistical.value));
      listCustomer.value.forEach(x => {
        if(x.id == receiverStatistical.value.idZalo){
          receiverStatistical.value.zaloName = x.name;
        }
      })
      console.log("############# add member receiverStatistical 11111111: " + JSON.stringify(receiverStatistical.value));
      zaloRepository.createReceiverStatistical(receiverStatistical.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Thêm thành viên vào nhóm thành công',
              life: 2000
            });
            loadListStatistical();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    const deleteManager = (id: number) => {
      zaloRepository.deleteReceiverStatistical(id)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail: 'Xóa thành viên ra khỏi nhóm thành công',
              life: 2000
            });
            loadListStatistical();
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    return {
      error,
      listCustomer,
      addMember,
      deleteManager,
      filters,
      clearFilter,
      initFilters,
      receiverStatistical,
      listReceiverStatistical,
    }
  }
}

